
// game info - Achaea
import React from 'react'

export class GameInfoAchaea {
    constructor() {

        this._gauges = [];
        this._gauges.push ({ name : 'health', 'desc' : 'Health', curvar : 'hp', maxvar : 'maxhp', percentage: true, color : '#ff0000', coloroff: '#7f7f7f',
            tooltip : 'Health represents your physical well-being. When your health reaches 0 you will die.'
        });
        this._gauges.push ({ name : 'mana', 'desc' : 'Mana', curvar : 'mp', maxvar : 'maxmp', percentage: true, color : '#5050ff', coloroff: '#7f7f7f',
            tooltip : 'Mana represents mental strength. When you are out of mana, you will not be able to perform most mental actions.'
        });
        this._gauges.push ({ name : 'endurance', 'desc' : 'Endurance', curvar : 'ep', maxvar : 'maxep', percentage: true, color : '#d000d0', coloroff: '#7f7f7f',
            tooltip : 'Endurance represents how worn down you are by constant activity. It goes up naturally and when you run out of endurance, you\'ll be unable to perform many taxing physical actions.'
        });
        this._gauges.push ({ name : 'willpower', 'desc' : 'Willpower', curvar : 'wp', maxvar : 'maxwp', percentage: true, color : '#ffff00', coloroff: '#7f7f7f', textcolor: '#000000',
            tooltip : 'Willpower represents long-term mental fatigue. It will decrease when you use most mental abilities and increase naturally.'
        });
        
        this._balances = [];
        this._balances.push ({name : 'balance', class : 'bal', desc : 'Balance', curvar : 'bal',
            tooltip : 'Most physical abilities require that you have Balance and will take away your Balance for a few seconds after you use the ability.'
        });
        this._balances.push ({name : 'equilibrium', class : 'eq', desc : 'Equilibrium', curvar : 'eq',
            tooltip : 'Most magical or mental abilities will require that you have \'Equilibrium\' (or simply \'eq\') and will use it up for a few seconds when you use the ability.'
        });
    }

    server_name(secondary = false) {
        if (secondary) return 'ironrealms.com';
        return 'achaea.com';
    }

    server_port(encrypted, secondary = false) {
        if (secondary) return 12001;
        if (encrypted) return 443;
        return 80;
    }
    
    is_ire_game() { return true; }
    websock_dir() { return '/socket/'; }
    game_short() { return 'Achaea'; }
    game_name() { return 'achaea'; }
    game_long() { return 'Achaea, Dreams of Divine Lands'; };
    root_url() { return 'https://www.'+this.server_name(false)+'/' };
    vote_url() { return this.root_url()+'vote' };
    password_url() { return this.root_url()+'game/recover' };
    script_url() { return 'https://client.'+this.server_name(false)+'/' };
    files_url() { return '/games/' + this.game_name(); }
    text_creation() { return '2'; }
    button_count() { return 7; }
    css_style() { return 'standard'; }
    gauges() { return this._gauges; }
    balances() { return this._balances; }
    rift_name() { return 'Rift'; }
    command_rift_in() { return 'inr'; }
    command_rift_out() { return 'outr'; }
    money() { return 'Gold'; }
    intro_sound() { return 'achaea'; }
    avatar_count() { return 60; }
    discord_url() { return 'https://discord.gg/2v2upFTj8G'; }
    facebook_url() { return 'https://www.facebook.com/Achaea'; }
    twitter_url() { return 'https://twitter.com/AchaeaIRE'; }

    google_analytics() { return 'UA-25176723-1'; }

    default_tabs() {
        return {
            container_1: [ 'map' ],
            container_2: [ 'affdef', 'skills', 'inventory' ],
            container_3: [ 'room', 'who', 'news' ],
            container_4: [ 'tasks', 'all_comm', 'quests' ],
            disabled: [ 'gauges', 'buttons' ],
            main_container: [ "output_main" ]
        };
    }

    // creation
    creation_stages() { return 4; }
    creation_valid_choice(type, id, creation_dlg) {
        if (type === 'city') {
            let cl = creation_dlg.selected_obj('class');
            if (!cl) return true;
            
            let city = creation_dlg.get_obj(type, id);
            if (!city) return true;
            let restrict = city.restricted_classes;
            for (let r in restrict)
                if (restrict[r] === cl.id) return false;
            return true;
        }
        return true;
    }
    
    creation_step(step) {
        if (step === 1) return 'race';
        if (step === 2) return 'class';
        if (step === 3) return 'city';
        if (step === 4) return 'name';
        return '';
    }

    creation_short_title(step) {
        if (step === 1) return 'Race';
        if (step === 2) return 'Class';
        if (step === 3) return 'City';
        if (step === 4) return 'Description';
        return '';
    }

    creation_title(step) {
        if (step === 1) return 'Select Your Race';
        if (step === 2) return 'Select Your Class';
        if (step === 3) return 'Select Your City';
        if (step === 4) return 'Describe Your Character';
        return '';
    }

    creation_prepare_data(data) {
        let url = 'https://www.achaea.com/local/nexus_data/';
        let baseurl = url;
        let raceurl = url + 'race-class/';
        for (let r in data['race']) {
            let img = data['race'][r].imageURL;
            data['race'][r].imageURL = url + 'races/' + img;
            data['race'][r].mobileImageURL = url + 'mobile/races/' + img;
            data['race'][r].thumbImageURL = baseurl + 'races/thumbnails-new/' + data['race'][r].thumbImageURL;
            data['race'][r].blurb += "\n\n<p class='white'>Race Bonuses:</p>" + data['race'][r].advantages;
            let name = data['race'][r].name.toLowerCase();
            // data['race'][r].information = [ { 'type' : 'heading', 'text' : 'Heading 1' }, { 'type' : 'text', 'text' : 'This is a text.' }, { 'type' : 'select' }, { 'type' : 'heading', 'text' : 'Heading 2' }, { 'type' : 'item', 'text' : 'This is a second text.' } ];
/*
            if (name.startsWith('fayad')) {
                data['race'][r].imageURL = url + 'default.jpg';
                data['race'][r].mobileImageURL = url + 'mobile/default.jpg';
            }
*/
            // temporary data
            if (data['race'][r]['name'] == 'Atavian (Winged human)')
                data['race'][r].imageList = [
                    { 'caption' : 'An Atavian Paladin flying into the skies.', 'url' : raceurl + 'atavian-paladin.jpg' },
                    { 'caption' : 'An Atavian Pariah in the desert.', 'url' : raceurl + 'atavian-pariah.jpg' },
                    { 'caption' : 'An Atavian Runewarden, sword drawn, in a dark cave.', 'url' : raceurl + 'atavian-runewarden.jpg' },
                    { 'caption' : 'An Atavian Sentinel with his wolf companion.', 'url' : raceurl + 'atavian-sentinel.jpg' },
                    { 'caption' : 'An Atavian Shaman with voodoo doll in hand.', 'url' : raceurl + 'atavian-shaman.jpg' }
                ];
        }
        for (let c in data['class']) {
            let name = data['class'][c].name.toLowerCase();
            let classURL = url + 'classes-new/' + name + '/';
            data['class'][c].imageURL = url + 'classes-new/' + name + '.jpg';
            data['class'][c].mobileImageURL = url + 'mobile/classes/' + name + '.jpg';
            data['class'][c].thumbImageURL = baseurl + 'classes-new/thumbnails/' + name + '.jpg';

            if ((name != 'depthswalker') && (name != 'occultist') && (name != 'psion') && (name != 'shaman') && (name != 'sylvan') && (name != 'unnamable')) {
                let imgcount = 2;
                if ((name == 'apostate') || (name == 'monk')) imgcount = 3;
                if ((name == 'druid') || (name == 'infernal') || (name == 'paladin') || (name == 'sentinel')) imgcount = 4;
                if (name == 'runewarden') imgcount = 5;
                if (name == 'magi') imgcount = 6;
                let imglist = [];
                for (let iid = 1; iid <= imgcount; ++iid)
                    imglist.push({ 'caption' : '', 'url' : classURL + iid+'.jpg' });
                data['class'][c].imageList = imglist;
            }

        }
        for (let c in data['city']) {
            // data['city'][c].shortName = data['city'][c].name.replace('the City of ', '');
            let img = data['city'][c].imageURL;
            data['city'][c].imageURL = url + 'cities-new/' + img;
            data['city'][c].mobileImageURL = url + 'mobile/cities/' + img;
            data['city'][c].thumbImageURL = baseurl + 'cities-new/thumbnails/' + img;
        }
        return data;
    }

    genders() { return [ 'Male', 'Female' ]; }

    creation_genderreq (type, e) {
        if (type !== 'race') return null;
        let req = e.genderReq;
        if (req === 0) return null;
        let list = this.genders();
        return list[req-1];
    }
    creation_genderreq_text(type, e) {
        let g = this.creation_genderreq(type, e);
        if (!g) return '';
        return 'This race is comprised only of ' + g + 's.';
    }
    creation_restriction(type, e, creation_dlg) {
        if (type !== 'city') return null;
        // Can they join any other city?
        let allow = creation_dlg.obj_allowed_list(type);
        if (allow.length !== 1) return;
        return allow[0];
    }
    creation_restriction_text(type, e, creation_dlg) {
        let allow = this.creation_restriction(type, e, creation_dlg);
        if (!allow) return null;
        return 'Your class can only join ' + allow.name + '.';
    }

    creation_nameform_image(data, creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        if (!race) return null;
        return race.imageURL;
    }

    creation_data(creation_dlg) {
        let race = creation_dlg.selected_obj('race');
        let cl = creation_dlg.selected_obj('class');
        let city = creation_dlg.selected_obj('city');
        if ((!race) || (!cl) || (!city)) return null;

        return "raceID\n" + race.id + "\nclass\n" + cl.id + "\ncity\n" + city.id;
    }

    
    login_extras() {
        let link = (<a style={{position: 'absolute', left: '48px', bottom: '50px', height: '29px', width: '159px', backgroundImage: 'url(\'/games/achaea/images/achaea-learn-more.png\')',  backgroundRepeat: 'no-repeat'}} target='_blank' rel='noopener noreferrer' href='https://www.achaea.com' />);
        let add = (<div style={{position: 'absolute', left: '-285px', marginTop: '-225px', top: '50%', width: '257px', height: '450px', zIndex: 2, backgroundImage: 'url(\'/games/achaea/images/achaea-info.png\')', backgroundRepeat: 'no-repeat'}}>{link}</div>);
        return add;
    }

    introText() {
        return [ 
            'In the vast realm of Achaea, you can choose from one of 20 unique classes. Become a fierce Blademaster, mastering the dual arts of combat, or an ingenious Alchemist, crafting wondrous elixirs.',
            'Roam through diverse landscapes, from enchanted forests to perilous deserts, each filled with secrets and dangers.',
            'Immerse yourself in deep role-playing, challenge foes in intense combat arenas, and perfect your skills with intricate crafting. Forge alliances, weave through political intrigue, and leave your mark on the ever-changing story of Achaea.',
            'Your journey awaits!'
        ];
    }

    introButtonColor() { return '#30B4BE'; }

    backgroundImage() {
        return this.files_url() + '/images/background.jpg';
    }

    creationImage() {
        return this.files_url() + '/images/creation.jpg';
    }

    blurb1() {
        return 'Enter Achaea, where intrigue and conquest await, and every character has a chance to make history.';
    }
    
    blurb2() {
        return 'Adventurers traverse dangerous forests, enchanted cities, and dungeons, uncovering gold, secrets, and fame.';
    }
    
    blurb3() {
        return 'Whether you’re a hero, mercenary, or mastermind, Achaea empowers you to shape your path according to your character’s ideals and ambitions.';
    }
}
